


















































import gql from 'graphql-tag'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TaskEditorCreate extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string

  name = ''
  creating = false
  nameErrors : string[] = []

  async create () {
    this.creating = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation createTask ($environmentId : ID, $name: String) {
          createTask (environmentId: $environmentId, name: $name) {
            _id
          }
        }`,
        variables: {
          environmentId: this.environmentId,
          name: this.name
        }
      })
      const { _id:taskId } = data.createTask
      this.$emit('created')
      this.$router.push({
        name: 'adminTaskEdit',
        params: {
          environmentId: this.environmentId,
          componentId: taskId
        }
      })
    } catch (e) {
      console.error(e)
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err : any) => {
          if (err.error === 'validationError') {
            this.nameErrors = ['Debe ser único']
          }
        })
      }
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar crear la tarea.'
      })
    } finally {
      this.creating = false
    }
  }
}
